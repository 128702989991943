import { observable, action, makeObservable } from "mobx";
import { trackPage } from "utils/track";
import { BasicUi, Toast, Video, EndScreenOverlay, NotesOverlay } from "types/strapi";
import { VideoEnums } from "enums/Video";

export class Store {
    locale: string | undefined = undefined;
    locales: string[] = [];
    localeNames: string[] = [];

    isConnected: boolean = false;
    showSequenceOverlay: boolean = false;
    showSequenceBar: boolean = false;
    currentVideo: string = VideoEnums.BEGINNING;
    showNotesOverlay: boolean = false;
    showEndScreenOverlay: boolean = false;

    /**
     * Localized data
     */
    basicUiDataLocalized: BasicUi | undefined = undefined;
    toastDataLocalized: Toast | undefined = undefined;
    videoDataLocalized: Video | undefined = undefined;
    endScreenOverlayDataLocalized: EndScreenOverlay | undefined = undefined;
    notesOverlayDataLocalized: NotesOverlay | undefined = undefined;

    constructor() {
        makeObservable(this, {
            locale: observable,
            locales: observable,
            localeNames: observable,

            isConnected: observable,
            showSequenceOverlay: observable,
            showSequenceBar: observable,
            currentVideo: observable,
            showNotesOverlay: observable,
            showEndScreenOverlay: observable,

            updateIsConnected: action,
            updateShowSequenceOverlay: action,
            updateShowSequenceBar: action,
            updateCurrentVideo: action,
            updateShowNotesOverlay: action,

            /**
             * Localized data
             */
            basicUiDataLocalized: observable,
            toastDataLocalized: observable,
            videoDataLocalized: observable,
            endScreenOverlayDataLocalized: observable,
            notesOverlayDataLocalized: observable,

            updateBasicUiDataLocalized: action,
            updateToastDataLocalized: action,
            updateVideoDataLocalized: action,
            updateEndScreenOverlayDataLocalized: action,
            updateNotesOverlayDataLocalized: action,
        });

        this.updateLocale = this.updateLocale.bind(this);
        this.updateLocales = this.updateLocales.bind(this);
        this.updateLocaleNames = this.updateLocaleNames.bind(this);
        this.updateIsConnected = this.updateIsConnected.bind(this);
        this.updateShowSequenceOverlay = this.updateShowSequenceOverlay.bind(this);
        this.updateShowSequenceBar = this.updateShowSequenceBar.bind(this);
        this.updateCurrentVideo = this.updateCurrentVideo.bind(this);
        this.updateShowNotesOverlay = this.updateShowNotesOverlay.bind(this);
        this.updateEndScreenOverlay = this.updateEndScreenOverlay.bind(this);

        this.updateBasicUiDataLocalized = this.updateBasicUiDataLocalized.bind(this);
        this.updateToastDataLocalized = this.updateToastDataLocalized.bind(this);
        this.updateVideoDataLocalized = this.updateVideoDataLocalized.bind(this);
        this.updateEndScreenOverlayDataLocalized = this.updateEndScreenOverlayDataLocalized.bind(this);
        this.updateNotesOverlayDataLocalized = this.updateNotesOverlayDataLocalized.bind(this);
    }

    updateLocale(newValue: string, isInitializer?: boolean): void {
        if (isInitializer) {
            trackPage("Loading-Screen", "Start");
        }

        this.locale = newValue;
    }

    updateLocales(newValue: string[]): void {
        this.locales = newValue;
    }

    updateLocaleNames(newValue: string[]): void {
        this.localeNames = newValue;
    }

    updateIsConnected(newValue: boolean): void {
        this.isConnected = newValue;
    }

    updateShowSequenceOverlay(newValue: boolean): void {
        this.showSequenceOverlay = newValue;
    }

    updateShowSequenceBar(newValue: boolean): void {
        this.showSequenceBar = newValue;
    }

    updateCurrentVideo(newValue: string): void {
        this.currentVideo = newValue;
    }

    updateShowNotesOverlay(newValue: boolean): void {
        this.showNotesOverlay = newValue;
    }

    updateEndScreenOverlay(newValue: boolean): void {
        this.showEndScreenOverlay = newValue;
    }

    updateBasicUiDataLocalized(newValue: BasicUi): void {
        this.basicUiDataLocalized = newValue;
    }

    updateToastDataLocalized(newValue: Toast): void {
        this.toastDataLocalized = newValue;
    }

    updateVideoDataLocalized(newValue: Video): void {
        this.videoDataLocalized = newValue;
    }

    updateEndScreenOverlayDataLocalized(newValue: EndScreenOverlay): void {
        this.endScreenOverlayDataLocalized = newValue;
    }

    updateNotesOverlayDataLocalized(newValue: NotesOverlay): void {
        this.notesOverlayDataLocalized = newValue;
    }
}

const store = new Store();
export default store;
